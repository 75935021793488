<template>
  <div>
    <div class="content-box" style="padding-top: 4px">
      <div class="content-main">
        <ayl-table :table="table" :is-radio-right="true" @radio-change="onRadioChange">
          <div slot="ctrl-button">
            <!-- {{dataFromDialog}} -->
            <el-button plain @click="onSubmit" style="float:right;" type="primary">提交分配</el-button>
            <el-button
              plain
              @click="onCancel"
              style="float:right;margin-right:16px"
              type="danger"
            >关闭</el-button>
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";

export default {
  name: "driver-scheduling",
  props: {
    dataFromDialog: {
      //从弹窗获得传来的参数等数据，！！！！名称固定！！！！
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    const vm = this;
    return {
      nav: [{ name: "分配司机参与排班" }],
      debug: 0,
      dataChanged: false, //数据是否改变了
      selectedRow: null, //选中的行
      table: {
        api: vm.$api_hw.scheduling_driverScheduleDetail,
        query: {
          bidId: null,
          personTypeList: null,
          queryContent: null
        },
        // 是否显示分页
        hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "input",
            model: "queryContent",
            placeholder: "姓名"
          }
        ],
        columns: [
          {
            title: "选择",
            type: "radio",
            model: "selectedRow"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "姓名",
            key: "name",
            width: "180px"
          },
          {
            title: "人员类型",
            key: "personTypeDes"
          },
          {
            title: "电话",
            key: "phone"
          }
        ]
      }
    };
  },
  methods: {
    onRadioChange(row) {
      this.selectedRow = row;
    },
    /**
     * 排班分配
     */
    async onSubmit() {
      let self = this;
      if (self.selectedRow) {
        try {
          await self.$api_hw.scheduling_updateDutyCarDriver({
            personId: self.selectedRow.personId,
            routeId: self.dataFromDialog.routeId,
            type: self.dataFromDialog.type
          });
          self.log({
            personId: self.selectedRow.personId,
            routeId: self.dataFromDialog.routeId,
            type: self.dataFromDialog.type
          });
          self.$notify({
            title: "成功",
            message: `${self.nav[0].name}成功`,
            type: "success"
          });
          BUS.$emit(BUSEVENT.REFRESH_SCHEDULING_LIST, 1);
          self.$emit("onClose"); //关闭弹窗
        } catch (e) {
          console.error(e);
        }
      }      
    },
    onCancel() {
      let self = this;
      if (self.dataChanged) {
        MessageBox.confirm(
          `您有数据未保存，确认 [关闭] 吗？`,
          "注意",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
        .then(_ => {
          self.$emit("onClose"); //关闭弹窗
        })
        .catch(_ => {});
      } else {
        self.$emit("onClose"); //关闭弹窗
      }
    }
  },
  watch:{
    dataFromDialog:function(v){
      Object.assign(this.table.query, this.dataFromDialog);
    }
  },
  async mounted() {
    // Object.assign(this.table.query, this.dataFromDialog);
    // await this.$search(this.table);
    this.log("mounted",this.table.query);
  }
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
  .drawer-main
    margin: 5px 15px
    .drawer-btn
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(237,237,237,1)
      border-radius: 2px
      outline: none
      border: 0
      &:hover
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
      &:before
        margin-bottom: 10px
        background: rgba(26,188,156,1)
        border-radius: 2px
        border: 0
        color: #fff
    .drawer-btn1
      width: 92px
      height: 36px
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      outline: none
      border: 1px solid transparent
      color: #fff

  .drawer-contract
    margin: 30px 15px 10px
    .drawer-contract-item
      width: 100%
      .drawer-contract-span
        color: #2E3033
    .drawer-contract-item1
      width: 48%
      .drawer-contract-span
        color: #2E3033
  .drawer-hr
    width: 100%
    height: 1px
    background: rgba(232,232,232,1)
  .drawer-map
    margin: 20px 15px 10px
    .drawer-map-title
      color: rgba(46,48,51,1)
  /deep/.el-drawer__header 
      margin-bottom: 15px
      padding-left: 15px
      color: #222
      font-size: 16px
  /deep/.el-button--success 
      color: #FFFFFF
      background-color: rgba(26,188,156,1)
      border-color: rgba(26,188,156,1)

</style>
